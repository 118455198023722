<template>
  <div>
    <search-tenant-orders @submit="submitSearch" @appointment="addAppointment"/>
    <a-table
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :pagination="false"
        row-key="id"
        @change="sortChange"
    >
      <div slot="order_no" slot-scope="order_no, record">
        <a @click="showInfoModal(record)">{{ order_no }}</a>
      </div>
      <div slot="record_type_display" slot-scope="text, record">
        <span :style="{color: orderColor(record.order_type)}">{{ text }}</span>
      </div>
      <div slot="order_status" slot-scope="text, record">
        <span :style="{color: orderStatusColor(record.order_status_slug)}">{{ text }}</span>
      </div>
      <div slot="dead_state" slot-scope="dead_state, record">
        <span v-if="record.record_type_slug !== 'service' ">-</span>
        <span v-else-if="dead_state">已逝世</span>
        <span v-else>在世</span>
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a
              @click="showSendObituaryModal(record)"
              v-if="record.record_type_slug === 'service' && !['unpaid', 'completed', 'cancelled', 'terminated', 'not_submit'].includes(record.order_status_slug)"
          >发送讣告</a>
          <a @click="showToDressRoomModal(record)" v-if="['received'].includes(record.order_status_slug)">送至穿衣间</a>
          <a @click="showProcessNotificationModal(record)" v-if="['received'].includes(record.order_status_slug)">发送相关手续告知书</a>
          <a
              @click="selectPackageModal(record)"
              v-if="['not_selected_package'].includes(record.order_status_slug)"
          >选择套餐</a>
          <a @click="chooseHallModal(record)"
             v-if="['not_selected_hall'].includes(record.order_status_slug)">选择厅房</a>
          <a @click="changeHallModal(record)"
             v-if="['unconfirmed_other'].includes(record.order_status_slug) && ['to_dress_room','to_dress','to_hall'].includes(record.body_dress_task_status_slug)">修改厅房</a>
          <a
              @click="showDetermineOtherItemsModal(record)"
              v-if="['unconfirmed_other'].includes(record.order_status_slug) && !record.is_confirm_other"
          >确定其他项目</a>
          <a
              @click="showConfirmFuneralHandoverModal(record)"
              v-if="['unconfirmed_other'].includes(record.order_status_slug)"
          >穿衣交接确认</a>
          <a
              @click="showAddItemsModal(record)"
              v-if="canAddOtherItems(record)"
          >新增项目</a>
         <a
             @click="showConfirmAuditoriumServiceModal(record)"
             v-if="!['unpaid','unconfirmed','not_received','received',
                              'not_selected_package','not_selected_hall','completed','terminated',
                              'cancelled','not_submit'].includes(record.order_status_slug) && record.record_type_slug === 'service'"
         >礼厅服务交接</a>
          <a
            @click="showUploadDeathImgModal(record)"
            v-if="hasUploadDeathImageOrVideoDropdownOp(record)"
          >上传遗像</a>
          <a
              @click="showUploadDeathVideoModal(record)"
              v-if="hasUploadDeathImageOrVideoDropdownOp(record)"
          >上传视频</a>
          <a-space v-if="!isTalkRole">
            <a
                @click="showConfirmOrderModal(record)"
                v-if="['unconfirmed'].includes(record.order_status_slug)"
            >确认订单</a>
            <a
                @click="showCancelServiceOrderModal(record)"
                v-if="['unconfirmed', 'stock', 'un_deliver', 'transport','not_submit'].includes(record.order_status_slug)"
            >取消订单</a>
            <a
                @click="subscribeOrderDeliverModal(record)"
                v-if="record.record_type_slug !== 'service' && ['un_deliver'].includes(record.order_status_slug)"
            >发货</a>
            <a @click="showLocationModal(record)"
               v-if="['not_received', 'transport'].includes(record.order_status_slug) && record.record_type_slug !== 'add_item'">实时位置</a>
            <a
                @click="sendWearArmbandNoticeModal(record)"
                v-if="['not_wear_armband_notice'].includes(record.order_status_slug)"
            >发送戴号提醒</a>
            <a
                @click="showUpdateOtherItemsModal(record)"
                v-if="['not_settlement'].includes(record.order_status_slug)"
            >修改项目</a>
            <a
                @click="wearArmband(record)"
                v-if="['not_hold_wear_armband_ceremony'].includes(record.order_status_slug)"
            >进行戴号仪式</a>
            <a
                @click="prepareProvideFoodCeremonyModal(record)"
                v-if="['not_prepare_provide_food_ceremony'].includes(record.order_status_slug)"
            >准备贡菜供饭仪式</a>
            <a
                @click="showSendFirstNightWatchingNoticeModal(record)"
                v-if="['not_first_night_watching'].includes(record.order_status_slug)"
            >发送第一守灵提醒</a>
            <a
                @click="confirmDirectorModal(record)"
                v-if="['unconfirmed_director_work'].includes(record.order_status_slug)"
            >确认理事工作</a>
            <a
                @click="showConfirmedLifeInfoModal(record)"
                v-if="['unconfirmed_life_info'].includes(record.order_status_slug)"
            >确认逝者生平事迹</a>
            <a
                @click="showSendLibationBoxModal(record)"
                v-if="['not_hold_libation_box_ceremony'].includes(record.order_status_slug)"
            >发送奠盒仪式提醒</a>
            <a
                @click="showSendSecondNightWatchingNoticeModal(record)"
                v-if="['not_second_night_watching'].includes(record.order_status_slug)"
            >发送第二守灵提醒</a>
            <a
                @click="showUploadEmceeDraftModal(record)"
                v-if="hasUploadEmceeDraftDropdownOp(record)"
            >上传司仪稿</a>


            <a
                @click="showSendThankfulCeremonyNoticeModal(record)"
                v-if="['not_hold_be_thankful_ceremony'].includes(record.order_status_slug)"
            >发送感恩仪式提醒</a>
            <a @click="showSendMealNoticeModal(record)"
               v-if="['not_meal'].includes(record.order_status_slug)">发送就餐通知</a>
            <a @click="billingDetailsModal(record)"
               v-if="record.record_type_slug === 'service' && record.is_show_settle && ['not_settlement', 'completed', 'terminated'].includes(record.order_status_slug)">结算详情</a>
            <a @click="showSendFuneralCeremonyModal(record)"
               v-if="hasFinishHoldFuneralDropdownOp(record)">发送告别出殡仪式提醒</a>
            <!--          <a @click="finishHoldFuneralModal(record)" v-if="hasFinishHoldFuneralDropdownOp(record)">完成出殡</a>-->

          </a-space>
          <a @click="printOrder(record)" :loading="loadingPDF"
             v-if="record.record_type_slug === 'service' && ['not_hold_funeral_ceremony', 'completed','terminated'].includes(record.order_status_slug)">打印</a>
          <a-dropdown
              v-if="hasDropdownMenu(record)"
          >
            <a class="ant-dropdown-link">
              更多操作 <a-icon type="down"/>
            </a>
            <a-menu slot="overlay" @click="handleMenuClick($event, record)">
              <a-menu-item
                  key="modifyMaster"
                  v-if="hasModifyMasterDropdownOp(record)"
              >
                修改丧属
              </a-menu-item>
              <a-menu-item
                  key="noticeUser"
                  v-if="hasNoticeUserDropdownOp(record)"
              >
                通知负责人
              </a-menu-item>
              <a-menu-item
                  key="modifyHeadUser"
                  v-if="hasModifyUserDropdownOp(record)"
              >
                修改负责人
              </a-menu-item>
              <a-menu-item
                  key="terminationllOrder"
                  v-if="hasTerminalOrderDropdownOp(record)"
              >
                终止订单
              </a-menu-item>
              <a-menu-item
                  key="printQr"
                  value="record"
                  v-if="hasPrintQrDropdownOp(record)"
              >
                打印二维码
              </a-menu-item>
              <a-menu-item
                  key="printHandoverPdf"
                  v-if="hasPrintHandoverPdfDropdownOp(record)"
              >
                打印穿衣交接单
              </a-menu-item>
              <a-menu-item
                  key="printRelevantProceduresPdf"
                  v-if="hasPrintRelevantProceduresPdfDropdownOp(record)"
              >
                打印相关手续告知书
              </a-menu-item>
              <a-menu-item
                  key="updateTransportation"
                  v-if="['unpaid', 'unconfirmed', 'not_received'].includes(record.order_status_slug) && record.record_type_slug === 'service'"
              >
                修改接运点
              </a-menu-item>
              <a-menu-item
                  key="auditoriumService"
                  v-if="!['unpaid','unconfirmed','not_received','received',
                        'not_selected_package','not_selected_hall',
                        'cancelled','not_submit'].includes(record.order_status_slug) && record.record_type_slug === 'service'"
              >
                打印礼厅服务交接单
              </a-menu-item>
              <a-menu-item
                  key="updateHallTime"
                  v-if="!['completed','terminated',
                      'cancelled'].includes(record.order_status_slug) && record.record_type_slug === 'service' && record.is_finish_director_task === true"
              >
                修改出厅时间
              </a-menu-item>
              <a-menu-item
                  key="updateThankfulTime"
                  v-if="!['completed','terminated',
                      'cancelled'].includes(record.order_status_slug) && record.record_type_slug === 'service' && record.is_finish_director_task === true"
              >
                修改感恩仪式时间
              </a-menu-item>
              <a-menu-item
                  key="updateFuneralTime"
                  v-if="!['completed','terminated',
                      'cancelled'].includes(record.order_status_slug) && record.record_type_slug === 'service' && record.is_finish_director_task === true"
              >
                修改出殡仪式时间
              </a-menu-item>

            </a-menu>
          </a-dropdown>
        </a-space>
      </span>
    </a-table>
    <pagination
        v-show="pagination.total_count > 0"
        :total-count="pagination.total_count"
        :page.sync="query.page"
        :per-page.sync="query.per_page"
        @change="fetchData"
    />
    <!-- 详情模态框 -->
    <show-order
        v-if="isShowInfoModal"
        :visible.sync="isShowInfoModal"
        :id="showingId"
        :order-type="orderType"
        @completed="fetchData"
    />
    <!-- 确认接单模态框 -->
    <show-confirm-order
        v-if="isShowConfirmOrderModal"
        :visible.sync="isShowConfirmOrderModal"
        :id="showingId"
        :service-order-id="serviceOrderId"
        :order-type="orderType"
        @completed="fetchData"
    />
    <!-- 确认接单模态框 -->
    <show-confirm-subscribe
        v-if="isShowConfirmSubscribeModal"
        :visible.sync="isShowConfirmSubscribeModal"
        :service-order-id="serviceOrderId"
        :order-type="orderType"
        :product-tasks="productTasks"
        @completed="fetchData"
    />
    <!-- 发货 -->
    <show-subscribe-order-deliver
        v-if="isShowSubscribeOrderDeliverModal"
        :visible.sync="isShowSubscribeOrderDeliverModal"
        :service-order-id="serviceOrderId"
        :order-type="orderType"
        @completed="fetchData"
    />
    <!--打印二维码 -->
    <show-print-qr
        v-if="isShowPrintQrModal"
        :visible.sync="isShowPrintQrModal"
        :id="id"
        :order-record="orderRecord"
        @completed="fetchData"
    />
    <!--修改丧属 -->
    <show-modify-master
        v-if="isShowModifyMasterModal"
        :visible.sync="isShowModifyMasterModal"
        :id="id"
        :name="name"
        :phone="phone"
        @completed="fetchData"
    />
    <!--修改丧属申请 -->
    <show-modify-master-apply
        v-if="isShowModifyMasterApplyModal"
        :visible.sync="isShowModifyMasterApplyModal"
        :id="id"
        :name="name"
        :phone="phone"
        @completed="fetchData"
    />
    <!--修改负责人 -->
    <show-modify-head-user
        v-if="isShowModifyHeadUserModal"
        :visible.sync="isShowModifyHeadUserModal"
        :id="id"
        :user-id="userId"
        @completed="fetchData"
    />
    <!--修改负责人申请 -->
    <show-modify-head-user-apply
        v-if="isShowModifyHeadUserApplyModal"
        :visible.sync="isShowModifyHeadUserApplyModal"
        :id="id"
        :name="name"
        :phone="phone"
        @completed="fetchData"
    />
    <!--取消订单 -->
    <show-cancel-service-order
        v-if="isShowCancelServiceOrderModal"
        :visible.sync="isShowCancelServiceOrderModal"
        :id="id"
        :order-type="orderType"
        :service-order-id="serviceOrderId"
        @completed="fetchData"
    />
    <!--终止订单 -->
    <show-termination-order
        v-if="isShowTerminationOrderModal"
        :visible.sync="isShowTerminationOrderModal"
        :id="id"
        :service-order-id="serviceOrderId"
        @completed="fetchData"
    />
    <!--送到穿衣间 -->
    <show-to-dress-room
        v-if="isShowToDressRoomModal"
        :visible.sync="isShowToDressRoomModal"
        :id="id"
        :order-type="orderType"
        :service-order-id="serviceOrderId"
        @completed="fetchData"
    />
    <!--确认理事 -->
    <show-confirm-director
        v-if="isShowConfirmDirectorModal"
        :visible.sync="isShowConfirmDirectorModal"
        :service-order-id="serviceOrderId"
        :id="id"
        @completed="fetchData"
    />
    <!--发送办理相关手续告知书 -->
    <show-process-notification
        v-if="isShowProcessNotificationModal"
        :visible.sync="isShowProcessNotificationModal"
        :id="id"
        @completed="fetchData"
    />
    <!--选择厅房 -->
    <show-choose-hall
        v-if="isShowChooseHallModal"
        :visible.sync="isShowChooseHallModal"
        :id="id"
        :hall-id="hallId"
        :stop-coffins-times="stopCoffinsTimes"
        :unit="unit"
        :is-change-hall="isChangeHall"
        :is-disinfect-old="isDisinfect"
        :is-buddhist-venue-old="isBuddhistVenue"
        :package-included-buddhist="packageIncludedBuddhist"
        :package-included-disinfect="packageIncludedDisinfect"
        @completed="fetchData"
    />
    <!--发送戴号提醒 -->
    <show-sendWear-armband-notice
        v-if="isShowSendWearArmbandNoticeModal"
        :visible.sync="isShowSendWearArmbandNoticeModal"
        :service-order-id="serviceOrderId"
        :hall-name="hallName"
        :death-name="deathName"
        :funeral-home-id="funeralHomeId"
        @completed="fetchData"
    />
    <!--制作讣告 -->
    <show-send-obituary
        v-if="isShowSendObituaryModal"
        :visible.sync="isShowSendObituaryModal"
        :service-order-id="serviceOrderId"
        @completed="fetchData"
    />
    <!--准备供菜供饭任务 -->
    <show-prepare-provide-food-ceremony
        v-if="isShowPrepareProvideFoodCeremonyModal"
        :visible.sync="isShowPrepareProvideFoodCeremonyModal"
        :service-order-id="serviceOrderId"
        :hall-name="hallName"
        :death-name="deathName"
        :funeral-home-id="funeralHomeId"
        @completed="fetchData"
    />
    <!--发送第一夜守灵提醒 -->
    <show-send-first-night-watching-notice
        v-if="isShowSendFirstNightWatchingNoticeModal"
        :visible.sync="isShowSendFirstNightWatchingNoticeModal"
        :service-order-id="serviceOrderId"
        :hall-name="hallName"
        :death-name="deathName"
        :funeral-home-id="funeralHomeId"
        @completed="fetchData"
    />
    <!--发送第二夜守灵提醒 -->
    <show-send-Second-night-watching-notice
        v-if="isShowSendSecondNightWatchingNoticeModal"
        :visible.sync="isShowSendSecondNightWatchingNoticeModal"
        :service-order-id="serviceOrderId"
        :hall-name="hallName"
        :death-name="deathName"
        :funeral-home-id="funeralHomeId"
        @completed="fetchData"
    />
    <!--确认逝者生平事迹 -->
    <show-confirmed-life-info
        v-if="isShowConfirmedLifeInfoModal"
        :visible.sync="isShowConfirmedLifeInfoModal"
        :service-order-id="serviceOrderId"
        :id="id"
        @completed="fetchData"
    />
    <!--上传司仪稿 -->
    <show-upload-emcee-draft
        v-if="isShowUploadEmceeDraftModal"
        :visible.sync="isShowUploadEmceeDraftModal"
        :service-order-id="serviceOrderId"
        @completed="fetchData"
    />
    <!--上传遗像 -->
    <show-upload-death-image
        v-if="isShowUploadDeathImgModal"
        :visible.sync="isShowUploadDeathImgModal"
        :service-order-id="serviceOrderId"
        @completed="fetchData"
    />
    <!--上传视频 -->
    <show-upload-death-video
        v-if="isShowUploadDeathVideoModal"
        :visible.sync="isShowUploadDeathVideoModal"
        :service-order-id="serviceOrderId"
        @completed="fetchData"
    />
    <!--发送奠盒仪式提醒 -->
    <show-send-libation-box
        v-if="isShowSendLibationBoxModal"
        :visible.sync="isShowSendLibationBoxModal"
        :service-order-id="serviceOrderId"
        :hall-name="hallName"
        :death-name="deathName"
        :funeral-home-id="funeralHomeId"
        @completed="fetchData"
    />

    <send-funeral-ceremony-notice
        v-if="isShowSendFuneralCeremonyModal"
        :visible.sync="isShowSendFuneralCeremonyModal"
        :service-order-id="serviceOrderId"
        :hall-name="hallName"
        :death-name="deathName"
        :funeral-home-id="funeralHomeId"
        :begin-time="funeralBeginTime"
        :end-time="funeralEndTime"
        @completed="fetchData"
    />
    <!--发送感恩仪式提醒 -->
    <show-send-thankful-ceremony
        v-if="isShowSendThankfulCeremonyNoticeModal"
        :visible.sync="isShowSendThankfulCeremonyNoticeModal"
        :service-order-id="serviceOrderId"
        :hall-name="hallName"
        :death-name="deathName"
        :funeral-home-id="funeralHomeId"
        :begin-time="thankfulBeginTime"
        :end-time="thankfulEndTime"
        @completed="fetchData"
    />
    <!--发送就餐提醒 -->
    <show-send-meal-notice
        v-if="isShowSendMealNoticeModal"
        :visible.sync="isShowSendMealNoticeModal"
        :service-order-id="serviceOrderId"
        :hall-name="hallName"
        :death-name="deathName"
        :begin-time="mealBeginTime"
        :end-time="mealEndTime"
        @completed="fetchData"
    />
    <!--完成出殡 -->
    <show-finish-funeral-ceremony
        v-if="isShowFinishHoldFuneralCeremonyModal"
        :visible.sync="isShowFinishHoldFuneralCeremonyModal"
        :service-order-id="serviceOrderId"
        @completed="fetchData"
    />
    <!-- 通知负责人 -->
    <send-user-notice
        v-if="isShowUserNoticeModal"
        :visible.sync="isShowUserNoticeModal"
        :service-order-id="serviceOrderId"
        :hall-name="hallName"
        :death-name="deathName"
        :order-no="orderNo"
        :order-user="orderUserName"
        @completed="fetchData"
    />
    <!--确定其他项目 -->
    <item-order-batch-record
        v-if="isShowDetermineOtherItemsModal"
        :visible.sync="isShowDetermineOtherItemsModal"
        :service-order-id="serviceOrderId"
        :id="id"
        @completed="fetchData"
    />
    <!--殡仪交接确认 -->
    <confirm-funeral-handover
        v-if="isShowConfirmFuneralHandoverModal"
        :visible.sync="isShowConfirmFuneralHandoverModal"
        :id="id"
        @completed="fetchData"
    />
    <modify-other-item
        v-if="isShowUpdateOtherItemsModal"
        :visible.sync="isShowUpdateOtherItemsModal"
        :service-order-id="serviceOrderId"
        :id="id"
        @completed="fetchData"
    />
    <!--选择套餐 -->
    <show-select-package
        v-if="isShowSelectPackageModalModal"
        :visible.sync="isShowSelectPackageModalModal"
        :id="id"
        :service-order-package-id="serviceOrderPackageId"
        :service-order-id="serviceOrderId"
        :funeral-home-id="funeralHomeId"
        @completed="fetchData"
    />
    <!--操作历史 -->
    <operation-history-list-modal
        v-if="isShowOperationHistoryModal"
        :reference-id="serviceOrderId"
        :reference-type="historyOrderType"
        :visible.sync="isShowOperationHistoryModal"
    />
    <!--  实时位置-->
    <show-real-location
        v-if="isShowRealLocationModal"
        :visible.sync="isShowRealLocationModal"
        :task-id="taskId"
    />
    <!--    新增其他项目-->
    <add-items
        v-if="isShowAddItemsModal"
        :service-order-id="serviceOrderId"
        :visible.sync="isShowAddItemsModal"
        @completed="fetchData"
    />
    <appointment v-if="isShowAppointmentModal" :visible.sync="isShowAppointmentModal" @completed="fetchData"/>
    <update-transportation
        v-if="isShowUpdateTransportationModal"
        :order-id="id"
        :visible.sync="isShowUpdateTransportationModal"
        @completed="fetchData"
    />
    <confirm-auditorium-service
        v-if="isShowConfirmAuditoriumServiceModal"
        :id="id"
        :visible.sync="isShowConfirmAuditoriumServiceModal"
        @completed="fetchData"
    />
    <update-hall-time
        :service-order-id="serviceOrderId"
        :visible.sync="isShowUpdateHallTimeModal"
        v-if="isShowUpdateHallTimeModal">

    </update-hall-time>
    <update-thankful-time
        :service-order-id="serviceOrderId"
        :visible.sync="isShowUpdateThankfulTimeModal"
        v-if="isShowUpdateThankfulTimeModal">
    </update-thankful-time>
    <update-funeral-time
        :service-order-id="serviceOrderId"
        :visible.sync="isShowUpdateFuneralTimeModal"
        v-if="isShowUpdateFuneralTimeModal"
    >
    </update-funeral-time>
  </div>
</template>

<script>
import {
  findTenantOrdersList,
  validUpdateOrderMaster,
  validUpdateOrderHeadUser,
  createSettlementPDF,
  printHandoverPdf, printRelevantProceduresPDF,
  wearArmband, createAuditoriumServicePdf
} from '@/api/order'
import SearchTenantOrders from '@/views/orders/tenant/Search'
import Pagination from '@/components/Pagination'
import printJS from 'print-js'
import {canAddOtherItems} from '@/utils/service_order'

export default {
  name: 'OrderTenantList',
  components: {
    Pagination,
    SearchTenantOrders,
    ShowOrder: () => import('@/views/orders/tenant/Show'),
    ShowConfirmOrder: () => import('@/views/orders/tenant/operation_modal/ConfirmOrder'),
    ShowPrintQr: () => import('@/views/orders/tenant/operation_modal/PrintQr'),
    ShowModifyMaster: () => import('@/views/orders/tenant/operation_modal/ModifyMaster'),
    ShowModifyMasterApply: () => import('@/views/orders/tenant/operation_modal/ModifyMasterApply'),
    ShowCancelServiceOrder: () => import('@/views/orders/tenant/operation_modal/CancelServiceOrder'),
    ShowToDressRoom: () => import('@/views/orders/tenant/operation_modal/ToDressRoom'),
    ShowProcessNotification: () => import('@/views/orders/tenant/operation_modal/ProcessNotification'),
    ShowChooseHall: () => import('@/views/orders/tenant/operation_modal/ChooseHall'),
    ShowSendWearArmbandNotice: () => import('@/views/orders/tenant/operation_modal/SendWearArmbandNotice'),
    ShowSendObituary: () => import('@/views/orders/tenant/operation_modal/SendObituary'),
    ShowPrepareProvideFoodCeremony: () => import('@/views/orders/tenant/operation_modal/PrepareProvideFoodCeremony'),
    ShowSendFirstNightWatchingNotice: () => import('@/views/orders/tenant/operation_modal/SendFirstNightWatchingNotice'),
    ShowSendSecondNightWatchingNotice: () => import('@/views/orders/tenant/operation_modal/SendSecondNightWatchingNotice'),
    ShowConfirmedLifeInfo: () => import('@/views/orders/tenant/operation_modal/ConfirmedLifeInfo'),
    ShowUploadEmceeDraft: () => import('@/views/orders/tenant/operation_modal/UploadEmceeDraft'),
    ShowUploadDeathImage: () => import('@/views/orders/tenant/operation_modal/UploadDeathImage'),
    ShowUploadDeathVideo: () => import('@/views/orders/tenant/operation_modal/UploadDeathVideo'),
    ShowSendLibationBox: () => import('@/views/orders/tenant/operation_modal/SendLibationBoxCeremonyNotice'),
    SendFuneralCeremonyNotice: () => import('@/views/orders/tenant/operation_modal/SendFuneralCeremonyNotice'),
    ShowSendMealNotice: () => import('@/views/orders/tenant/operation_modal/SendMealNotice'),
    ShowFinishFuneralCeremony: () => import('@/views/orders/tenant/operation_modal/FinishHoldFuneralCeremony'),
    ShowSendThankfulCeremony: () => import('@/views/orders/tenant/operation_modal/SendThankfulCeremonyNotice'),
    ShowModifyHeadUser: () => import('@/views/orders/tenant/operation_modal/ModifyHeadUser'),
    ShowModifyHeadUserApply: () => import('@/views/orders/tenant/operation_modal/ModifyHeadUserApply'),
    ShowSubscribeOrderDeliver: () => import('@/views/orders/tenant/operation_modal/SubscribeOrderDeliver'),
    ShowConfirmDirector: () => import('@/views/orders/tenant/operation_modal/ConfirmDirector'),
    ShowConfirmSubscribe: () => import('@/views/orders/tenant/operation_modal/ConfirmSubscribe'),
    ShowUpdateOtherItems: () => import('@/views/orders/tenant/operation_modal/UpdateOtherItems'),
    ShowSelectPackage: () => import('@/views/orders/tenant/operation_modal/SelectPackage'),
    ShowTerminationOrder: () => import('@/views/orders/tenant/operation_modal/TerminationOrder'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal'),
    ItemOrderBatchRecord: () => import('@/views/orders/tenant/operation_modal/ItemOrderBatchRecord'),
    ModifyOtherItem: () => import('@/views/orders/tenant/operation_modal/ModifyOtherItem'),
    ShowRealLocation: () => import('@/views/location/ShowRealLocation'),
    AddItems: () => import('@/views/orders/tenant/operation_modal/AddItems'),
    Appointment: () => import('@/views/orders/tenant/operation_modal/Appointment'),
    SendUserNotice: () => import('@/views/orders/tenant/operation_modal/SendUserNotice'),
    ConfirmFuneralHandover: () => import('@/views/orders/tenant/operation_modal/ConfirmFuneralHandover'),
    UpdateTransportation: () => import('@/views/orders/tenant/operation_modal/UpdateTransportation.vue'),
    ConfirmAuditoriumService: () => import('@/views/orders/tenant/operation_modal/ConfirmAuditoriumService.vue'),
    UpdateHallTime: () => import('@/views/orders/tenant/operation_modal/UpdateHallTime.vue'),
    UpdateThankfulTime: () => import('@/views/orders/tenant/operation_modal/UpdateThankfulTime.vue'),
    UpdateFuneralTime: () => import('@/views/orders/tenant/operation_modal/UpdateFuneralTime.vue'),
  },
  data() {
    return {
      query: {},
      data: [],
      isShowInfoModal: false, // 是否显示详情弹窗
      isShowConfirmOrderModal: false,
      isShowPrintQrModal: false,
      isShowModifyMasterModal: false,
      isShowModifyMasterApplyModal: false,
      isShowCancelServiceOrderModal: false,
      isShowToDressRoomModal: false,
      isShowProcessNotificationModal: false,
      isShowChooseHallModal: false,
      isShowSendWearArmbandNoticeModal: false,
      isShowSendObituaryModal: false,
      isShowPrepareProvideFoodCeremonyModal: false,
      isShowSendFirstNightWatchingNoticeModal: false,
      isShowConfirmedLifeInfoModal: false,
      isShowUploadEmceeDraftModal: false,
      isShowSendLibationBoxModal: false,
      isShowSendFuneralCeremonyModal: false,
      isShowSendSecondNightWatchingNoticeModal: false,
      isShowSendMealNoticeModal: false,
      isShowFinishHoldFuneralCeremonyModal: false,
      isShowSendThankfulCeremonyNoticeModal: false,
      isShowModifyHeadUserApplyModal: false,
      isShowModifyHeadUserModal: false,
      isShowSubscribeOrderDeliverModal: false,
      isShowConfirmDirectorModal: false,
      isShowConfirmSubscribeModal: false,
      isShowDetermineOtherItemsModal: false,
      isShowAddItemsModal: false,
      isShowAppointmentModal: false,
      isShowSelectPackageModalModal: false,
      isShowUpdateOtherItemsModal: false,
      isShowTerminationOrderModal: false,
      isShowOperationHistoryModal: false,
      isShowRealLocationModal: false,
      isShowUploadDeathImgModal: false,
      isShowUploadDeathVideoModal: false,
      isShowUserNoticeModal: false,
      isShowConfirmFuneralHandoverModal: false,
      loadingPDF: false,
      loading: true,
      showingId: 0,
      serviceOrderId: 0,
      id: 0,
      name: '',
      phone: '',
      hallName: '',
      deathName: '',
      orderType: '',
      historyOrderType: '',
      unit: '',
      stopCoffinsTimes: 0,
      orderRecord: {},
      orderStatus: '',
      orderNo: '',
      orderUserName: '',
      previewVisible: false,
      serviceOrderPackageId: 0,
      funeralHomeId: 0,
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      },
      taskId: 0,
      productTasks: [],
      isChangeHall: false,
      isDisinfect: false,
      isBuddhistVenue: false,
      packageIncludedBuddhist: true,
      packageIncludedDisinfect: true,
      isShowUpdateTransportationModal: false,
      isShowConfirmAuditoriumServiceModal: false,
      isShowUpdateHallTimeModal: false,
      isShowUpdateThankfulTimeModal: false,
      isShowUpdateFuneralTimeModal: false,
      thankfulBeginTime: '',
      thankfulEndTime: '',
      funeralBeginTime: '',
      funeralEndTime: '',
      mealBeginTime: '',
      mealEndTime: ''
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '订单号',
          dataIndex: 'order_no',
          scopedSlots: {customRender: 'order_no'},
          fixed: 'left',
          width: 180
        },
        {
          title: '逝者姓名',
          dataIndex: 'death_name',
          width: 120
        },
        {
          title: '使用厅房',
          dataIndex: 'hall_name'
        },
        {
          title: '申请人',
          width: 120,
          dataIndex: 'apply_name'
        },
        {
          title: '联系电话',
          width: 180,
          dataIndex: 'phone_number'
        },
        {
          title: '订单类型',
          dataIndex: 'record_type_display',
          scopedSlots: {customRender: 'record_type_display'},
          width: 120
        },
        {
          title: '订单状态',
          dataIndex: 'order_status',
          scopedSlots: {customRender: 'order_status'},
          width: 120
        },
        {
          title: '逝者状态',
          width: 90,
          dataIndex: 'dead_state',
          scopedSlots: {customRender: 'dead_state'}
        },
        {
          title: '订单来源',
          width: 90,
          dataIndex: 'source'
        },
        {
          title: '负责人',
          width: 120,
          dataIndex: 'head_name'
        },
        {
          title: '任务数量',
          width: 120,
          dataIndex: 'task_count'
        },
        {
          title: '订单创建时间',
          dataIndex: 'created_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          width: 250,
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: {customRender: 'actions'}
        }
      ]
    },

    isTalkRole() {
      return this.$store.getters.userRole === 'tenant_talk'
    }
  },
  methods: {
    orderColor(orderType) {
      switch (orderType) {
        case 'service':
          return '#eea907'
        case 'subscribe':
          return '#4dd31b'
        default:
          return this.$primaryColor
      }
    },

    orderStatusColor(orderStatus) {
      switch (orderStatus) {
        case 'not_submit':
          return '#920a0a'
        case 'unpaid':
          return '#e83e3e'
        case 'stock':
          return '#db7777'
        case 'canceled':
          return 'black'
        case 'cancelled':
          return 'black'
        case 'completed':
          return '#bab8b8'
        case 'terminated':
          return '#4e4c4c'
        case 'not_settlement':
          return '#eea907'
        default:
      }
    },

    addAppointment() {
      // 预约
      this.isShowAppointmentModal = true
    },
    canAddOtherItems(record) {
      return record.is_confirm_other && canAddOtherItems(record.record_type_slug, record.order_status_slug)
    },
    hasFinishHoldFuneralDropdownOp(record) {
      return ['not_hold_funeral_ceremony'].includes(record.order_status_slug)
    },
    hasPrintHandoverPdfDropdownOp(record) {
      // 打印交接单
      return !['unpaid', 'unconfirmed', 'not_submit', 'cancelled'].includes(record.order_status_slug) && record.record_type_slug === 'service'
    },
    hasPrintRelevantProceduresPdfDropdownOp(record) {
      // 打印相关手续告知书
      return !['unpaid', 'unconfirmed', 'not_received', 'not_submit', 'completed', 'cancelled', 'terminated'].includes(record.order_status_slug) && record.record_type_slug === 'service'
    },
    hasUploadEmceeDraftDropdownOp(record) {
      return !record.stopped && !record.is_finish_upload_emcee_draft && record.is_need_emcee_draft &&
          (['not_hold_libation_box_ceremony', 'not_second_night_watching', 'not_hold_be_thankful_ceremony', 'not_meal'].includes(record.order_status_slug) ||
              record.order_status_slug === 'not_settlement')
    },
    hasUploadDeathImageOrVideoDropdownOp(record) {
      return record.is_upload_death_img_or_video
    },
    hasTerminalOrderDropdownOp(record) {
      return record.record_type_slug === 'service' && ['not_received', 'received', 'not_selected_package', 'not_selected_hall', 'unconfirmed_other', 'not_wear_armband_notice', 'not_hold_wear_armband_ceremony', 'not_prepare_provide_food_ceremony', 'not_first_night_watching', 'unconfirmed_director_work', 'unconfirmed_life_info', 'not_hold_libation_box_ceremony', 'not_second_night_watching', 'not_hold_be_thankful_ceremony', 'not_meal'].includes(record.order_status_slug)
    },
    hasModifyUserDropdownOp(record) {
      // if (this.isTalkRole) {
      //   return false
      // }

      if (record.record_type_slug === 'service') {
        return !['terminated', 'cancelled', 'completed'].includes(record.order_status_slug)
      } else if (record.record_type_slug === 'add_item') {
        return !['canceled', 'completed'].includes(record.order_status_slug)
      } else if (record.record_type_slug === 'subscribe') {
        return !['cancelled', 'completed'].includes(record.order_status_slug)
      }
      return false
    },
    hasModifyMasterDropdownOp(record) {
      return record.record_type_slug === 'service' &&
          ['unconfirmed', 'not_received', 'received', 'not_selected_package', 'not_selected_hall', 'unconfirmed_other', 'not_wear_armband_notice', 'not_hold_wear_armband_ceremony', 'not_prepare_provide_food_ceremony', 'not_first_night_watching', 'unconfirmed_director_work', 'unconfirmed_life_info', 'not_hold_libation_box_ceremony', 'not_second_night_watching', 'not_hold_be_thankful_ceremony', 'not_meal', 'not_settlement', 'not_hold_funeral_ceremony'].includes(record.order_status_slug)
    },
    hasPrintQrDropdownOp(record) {
      return record.record_type_slug === 'service' && ['unconfirmed', 'not_received', 'received', 'not_selected_package', 'not_selected_hall', 'unconfirmed_other', 'not_wear_armband_notice', 'not_hold_wear_armband_ceremony', 'not_prepare_provide_food_ceremony', 'not_first_night_watching', 'unconfirmed_director_work', 'unconfirmed_life_info', 'not_hold_libation_box_ceremony', 'not_second_night_watching', 'not_hold_be_thankful_ceremony', 'not_meal', 'not_settlement', 'not_hold_funeral_ceremony'].includes(record.order_status_slug)
    },
    hasNoticeUserDropdownOp(record) {
      return this.isTalkRole && record.record_type_slug === 'service' &&
          ['not_wear_armband_notice', 'not_hold_wear_armband_ceremony', 'not_prepare_provide_food_ceremony', 'not_first_night_watching', 'unconfirmed_director_work', 'unconfirmed_life_info', 'not_hold_libation_box_ceremony', 'not_second_night_watching', 'not_hold_be_thankful_ceremony', 'not_meal', 'not_settlement', 'not_hold_funeral_ceremony'].includes(record.order_status_slug)
    },
    hasDropdownMenu(record) {
      return this.hasPrintQrDropdownOp(record) ||
          this.hasModifyMasterDropdownOp(record) ||
          this.hasModifyUserDropdownOp(record) ||
          this.hasTerminalOrderDropdownOp(record) ||
          this.hasPrintHandoverPdfDropdownOp(record) ||
          this.hasNoticeUserDropdownOp(record) ||
          this.hasPrintRelevantProceduresPdfDropdownOp(record)
    },
    handleMenuClick(e, record) {
      if (e.key === 'printQr') {
        this.isShowPrintQrModal = true
        this.id = record.id
        this.serviceOrderId = record.service_order_id
        this.orderRecord = record
      } else if (e.key === 'modifyMaster') {
        validUpdateOrderMaster(Object.assign({order_id: record.id})).then((res) => {
          if (res.data.result) {
            this.isShowModifyMasterModal = true
            this.id = record.id
            this.name = res.data.name
            this.phone = res.data.phone
          } else {
            this.isShowModifyMasterApplyModal = true
            this.name = res.data.name
            this.phone = res.data.phone
            this.id = record.id
          }
        })
      } else if (e.key === 'uploadEmceeDraft') {
        this.showUploadEmceeDraftModal(record)
      } else if (e.key === 'terminationllOrder') {
        this.isShowTerminationOrderModal = true
        this.serviceOrderId = record.service_order_id
        this.id = record.id
      } else if (e.key === 'finishHoldFuneral') {
        this.isShowFinishHoldFuneralCeremonyModal = true
        this.serviceOrderId = record.service_order_id
      } else if (e.key === 'updateOtherItems') {
        this.isShowUpdateOtherItemsModal = true
        this.serviceOrderId = record.service_order_id
        this.id = record.id
      } else if (e.key === 'operationHistory') {
        this.serviceOrderId = record.service_order_id
        this.isShowOperationHistoryModal = true
        if (record.record_type_slug === 'service') {
          this.historyOrderType = 'ServiceOrder'
        } else if (record.record_type_slug === 'subscribe') {
          this.historyOrderType = 'SubscribeOrder'
        } else {
          this.historyOrderType = 'AdditionalItemOrder'
        }
      } else if (e.key === 'modifyHeadUser') {
        validUpdateOrderHeadUser(Object.assign({order_id: record.id})).then((res) => {
          if (res.data.result) {
            this.isShowModifyHeadUserModal = true
            this.userId = record.user_id
            this.id = record.id
          } else {
            this.isShowModifyHeadUserApplyModal = true
            this.id = record.id
            this.name = res.data.name
            this.phone = res.data.phone
          }
        })
      } else if (e.key === 'printHandoverPdf') {
        this.$info({
          title: '正在生成穿衣交接PDF文件，请耐心等候',
          content: ''
        })
        printHandoverPdf(record.id).then((res) => {
          if (res.code === 0) {
            if (res.data.url) {
              printJS(res.data.url)
            }
          }
        })
      } else if (e.key === 'printRelevantProceduresPdf') {
        this.$info({
          title: '正在生成相关手续告知书PDF文件，请耐心等候',
          content: ''
        })
        printRelevantProceduresPDF(record.id).then((res) => {
          if (res.code === 0) {
            if (res.data.url) {
              printJS(res.data.url)
            }
          }
        })
      } else if (e.key === 'noticeUser') {
        this.isShowUserNoticeModal = true
        this.serviceOrderId = record.service_order_id
        this.deathName = record.death_name
        this.hallName = record.hall_name
        this.orderNo = record.order_no
        this.orderUserName = record.head_name
      } else if (e.key === 'updateTransportation') {
        this.showUpdateTransportationModal(record)
      } else if (e.key === 'auditoriumService') {
        if (!record.is_auditorium_service) {
          this.$info({
            title: '还未进行礼厅交接',
            content: ''
          })
          return
        }
        this.$info({
          title: '正在生成礼厅交接单PDF文件，请耐心等候',
          content: ''
        })
        createAuditoriumServicePdf(record.id).then((res) => {
          if (res.code === 0) {
            if (res.data.url) {
              printJS(res.data.url)
            }
          }
        })
      } else if (e.key === 'updateHallTime') {
        //修改进厅出厅时间
        this.isShowUpdateHallTimeModal = true
        this.serviceOrderId = record.service_order_id

      } else if (e.key === 'updateThankfulTime') {
        //修改感恩仪式时间
        this.isShowUpdateThankfulTimeModal = true
        this.serviceOrderId = record.service_order_id
      } else if (e.key === 'updateFuneralTime') {
        //修改出殡仪式时间
        this.isShowUpdateFuneralTimeModal = true
        this.serviceOrderId = record.service_order_id
      }
    },
    finishHoldFuneralModal(record) {
      this.isShowFinishHoldFuneralCeremonyModal = true
      this.serviceOrderId = record.service_order_id
    },
    showUpdateOtherItemsModal(record) {
      this.isShowUpdateOtherItemsModal = true
      this.serviceOrderId = record.service_order_id
      this.id = record.id
    },
    selectPackageModal(record) {
      this.isShowSelectPackageModalModal = true
      this.serviceOrderId = record.service_order_id
      this.serviceOrderPackageId = record.service_order_package_id
      this.funeralHomeId = record.funeral_home_id
      this.id = record.id
    },
    showDetermineOtherItemsModal(record) {
      this.isShowDetermineOtherItemsModal = true
      this.serviceOrderId = record.service_order_id
      this.id = record.id
    },
    showConfirmFuneralHandoverModal(record) {
      this.isShowConfirmFuneralHandoverModal = true
      this.id = record.id
    },
    showAddItemsModal(record) {
      this.isShowAddItemsModal = true
      this.serviceOrderId = record.service_order_id
    },
    sendWearArmbandNoticeModal(record) {
      this.isShowSendWearArmbandNoticeModal = true
      this.serviceOrderId = record.service_order_id
      this.deathName = record.death_name
      this.hallName = record.hall_name
      this.funeralHomeId = record.funeral_home_id
    },
    billingDetailsModal(record) {
      this.$router.push({
        name: 'tenant_termination_order',
        params: {id: record.id},
        query: {order_type: 'billing'}
      })
    },
    printOrder(record) {
      this.$info({
        title: '正在生成结算清单PDF文件，请耐心等候',
        content: ''
      })
      if (this.loadingPDF) {
        return
      }

      this.loadingPDF = true
      createSettlementPDF(record.id).then((res) => {
        if (res.code === 0) {
          if (res.data.url) {
            printJS(res.data.url)
          }
        }
      })
      this.loadingPDF = false
    },
    confirmDirectorModal(record) {
      this.isShowConfirmDirectorModal = true
      this.serviceOrderId = record.service_order_id
      this.id = record.id
    },
    subscribeOrderDeliverModal(record) {
      this.isShowSubscribeOrderDeliverModal = true
      this.serviceOrderId = record.service_order_id
      this.orderType = record.record_type_slug
    },
    showSendThankfulCeremonyNoticeModal(record) {
      this.isShowSendThankfulCeremonyNoticeModal = true
      this.serviceOrderId = record.service_order_id
      this.deathName = record.death_name
      this.hallName = record.hall_name
      this.funeralHomeId = record.funeral_home_id
      this.thankfulBeginTime = record.thankful_begin_time
      this.thankfulEndTime = record.thankful_end_time
    },
    finishHoldFuneralCeremonyModal(record) {
      this.isShowFinishHoldFuneralCeremonyModal = true
      this.serviceOrderId = record.service_order_id
    },
    showSendMealNoticeModal(record) {
      this.isShowSendMealNoticeModal = true
      this.serviceOrderId = record.service_order_id
      this.deathName = record.death_name
      this.hallName = record.hall_name
      this.mealBeginTime = record.meal_begin_time
      this.mealEndTime = record.meal_end_time
    },
    showSendLibationBoxModal(record) {
      this.isShowSendLibationBoxModal = true
      this.serviceOrderId = record.service_order_id
      this.deathName = record.death_name
      this.hallName = record.hall_name
      this.funeralHomeId = record.funeral_home_id
    },
    showSendFuneralCeremonyModal(record) {
      this.isShowSendFuneralCeremonyModal = true
      this.serviceOrderId = record.service_order_id
      this.deathName = record.death_name
      this.hallName = record.hall_name
      this.funeralHomeId = record.funeral_home_id
      this.funeralEndTime = record.funeral_end_time
      this.funeralBeginTime = record.funeral_begin_time
    },
    showUploadEmceeDraftModal(record) {
      this.isShowUploadEmceeDraftModal = true
      this.serviceOrderId = record.service_order_id
      this.id = record.id
    },
    showUploadDeathImgModal(record) {
      this.isShowUploadDeathImgModal = true
      this.serviceOrderId = record.service_order_id
      this.id = record.id
    },
    showUploadDeathVideoModal(record) {
      this.isShowUploadDeathVideoModal = true
      this.serviceOrderId = record.service_order_id
      this.id = record.id
    },
    showConfirmedLifeInfoModal(record) {
      this.isShowConfirmedLifeInfoModal = true
      this.serviceOrderId = record.service_order_id
      this.id = record.id
    },
    showSendFirstNightWatchingNoticeModal(record) {
      this.isShowSendFirstNightWatchingNoticeModal = true
      this.serviceOrderId = record.service_order_id
      this.deathName = record.death_name
      this.hallName = record.hall_name
      this.funeralHomeId = record.funeral_home_id
    },
    showSendSecondNightWatchingNoticeModal(record) {
      this.isShowSendSecondNightWatchingNoticeModal = true
      this.serviceOrderId = record.service_order_id
      this.deathName = record.death_name
      this.hallName = record.hall_name
      this.funeralHomeId = record.funeral_home_id
    },
    prepareProvideFoodCeremonyModal(record) {
      this.isShowPrepareProvideFoodCeremonyModal = true
      this.serviceOrderId = record.service_order_id
      this.deathName = record.death_name
      this.hallName = record.hall_name
      this.funeralHomeId = record.funeral_home_id
    },
    showConfirmAuditoriumServiceModal(record) {
      this.isShowConfirmAuditoriumServiceModal = true
      this.id = record.id
    },
    wearArmband(record) {
      wearArmband(record.service_order_id, {}).then((res) => {
        if (res.code === 0) {
          this.fetchData()
        }
      })
    },
    chooseHallModal(record) {
      this.isChangeHall = false
      this.isShowChooseHallModal = true
      this.id = record.id
      this.hallId = record.hall_id
      this.unit = record.unit
      this.stopCoffinsTimes = record.stop_coffins_times
      this.isDisinfect = true
      this.isBuddhistVenue = true
      this.packageIncludedBuddhist = true
      this.packageIncludedDisinfect = true
    },
    changeHallModal(record) {
      this.isChangeHall = true
      this.isShowChooseHallModal = true
      this.id = record.id
      this.hallId = record.hall_id
      this.unit = record.unit
      this.stopCoffinsTimes = record.stop_coffins_times
      this.isDisinfect = record.is_disinfect
      this.isBuddhistVenue = record.is_buddhist_venue
      this.packageIncludedDisinfect = record.package_included_disinfection
      this.packageIncludedBuddhist = record.package_included_buddhist_venue
    },
    showLocationModal(record) {
      this.isShowRealLocationModal = true
      this.taskId = record.task_id
    },
    showProcessNotificationModal(record) {
      this.isShowProcessNotificationModal = true
      this.id = record.id
    },
    showToDressRoomModal(record) {
      this.isShowToDressRoomModal = true
      this.id = record.id
      this.orderType = record.record_type_slug
      this.serviceOrderId = record.service_order_id
    },
    showCancelServiceOrderModal(record) {
      this.isShowCancelServiceOrderModal = true
      this.id = record.id
      this.serviceOrderId = record.service_order_id
      this.orderType = record.record_type_slug
    },
    showInfoModal(record) {
      this.showingId = record.id
      this.orderType = record.record_type_slug
      this.orderStatus = record.order_status_slug
      this.orderRecord = record
      this.isShowInfoModal = true
    },
    showConfirmOrderModal(record) {
      if (record.record_type_slug === 'service') {
        this.isShowConfirmOrderModal = true
        this.showingId = record.id
      } else {
        this.isShowConfirmSubscribeModal = true
        this.serviceOrderId = record.service_order_id
        this.showingId = record.id
        this.productTasks = record.product_tasks
      }
      this.orderType = record.record_type_slug
      this.serviceOrderId = record.service_order_id
    },
    showSendObituaryModal(record) {
      this.isShowSendObituaryModal = true
      this.serviceOrderId = record.service_order_id
      this.id = record.id
    },
    showUpdateTransportationModal(record) {
      this.id = record.id
      this.isShowUpdateTransportationModal = true
    },
    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
          this.$options.data().query,
          search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTenantOrdersList(Object.assign(this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
